import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "../../redux/action/AuthAction";
import Logo from "../../image/Logo.png";
import user from "../../image/user.png";

const Navbar = () => {
  const [navheight, setNavheight] = useState(0);
  // const { isSigninIn, setLigin } = useState(0);

  const ref = useRef(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.Auth);
  const { isSigninIn, userResult } = userInfo;
  console.log(isSigninIn, "isSigninIn");
  useEffect(() => {
    setNavheight(ref.current.clientHeight);
  }, []);
  const logoutHandler = () => {
    dispatch(signOut());
  };


  return (
    <>
      <div style={{ paddingTop: 109 }}>{/* {navheight} */}</div>
      <nav className="navbar navbar-expand-lg navbar-light" ref={ref}>
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={Logo} alt="" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-NavLink" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-NavLink" to="/hotel-booking">
                  Hotel Booking
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-NavLink" to="/flight-booking">
                  Flight Booking
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-NavLink" to="/taxi-booking">
                  Taxi Booking
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-NavLink" to="/tour-package">
                  Tour Packages
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-NavLink" to="/special-offer">
                  Special Offers
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink className="nav-NavLink" to="/contact">
                  Contact Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-NavLink" to="/corporateenquiry">
                  Corporate enquiry
                </NavLink>
              </li>
              <a
        href="http://admin.fluttertourism.com/"
        target="_blank"
        rel="noreferrer"
      >
        Vendor Login
        </a>
              {/* <li className="nav-item">
                <Link className="nav-NavLink" to="#!">
                  Gallery
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-NavLink" to="#!">
                  Contact US
                </Link>
              </li> */}
            </ul>
            {/* <Link to="signin">
              <button type="button" className="btn btn-primary sign">
                Sign in
              </button>
            </Link>
            <Link to="registration">
              <button type="button" className="btn btn-primary create_account">
                Creact account
              </button>
            </Link> */}
          </div>

          {isSigninIn === true ? (
            <div className="dropdown userlt">
              <button
                className="dropdown-toggle tog"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded "
                  src={user}
                  alt="header-user"
                />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  <Link to="/dashboard">Profile</Link>
                  <Link onClick={logoutHandler} className="dropdown-item" to="">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          ) : (
            <div className="dropdown userlt">
              <Link to="signin">
                <button type="button" className="btn btn-primary sign">
                  Sign in
                </button>
              </Link>
              {/* 
              <Link to="registration">
                <button
                  type="button"
                  className="btn btn-primary create_account"
                >
                  Creact account
                </button>
              </Link>*/}

              {/* <div>
                <button
                  className="dropdown-toggle tog"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded "
                    src={user}
                    alt="header-user"
                  />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <Link className="dropdown-item" to="/signin">
                      LogIn
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
