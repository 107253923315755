       
import setAuthorizationToken from "../../utils/setAutorization";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import {
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_REQUEST,
  SIGNIN_USER_ERROR,
  SET_CURRENT_USER,
  SIGNOUT_USER,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
} from "../constant/AuthConstant";
const axios = require("axios");

// USER AUTH ACTION
export const signinUser = (email, password) => {
  // const navigate = useNavigate();
  return function (dispatch) {
    dispatch({
      type: SIGNIN_USER_REQUEST,
      payload: true,
    });

    let OPTIONS = {
      // url: "http://103.145.51.109:3004/api/v1/login",
      url: `${process.env.REACT_APP_API_PATH}/api/v1/login`,
      method: "POST",
      data: {
        email: email,
        password: password,
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTIONS)
      .then((res) => {
        console.log("res", res.data);

        const token = res.data.token;
        localStorage.setItem("token", token);
        setAuthorizationToken(token);
        dispatch(setCurrentUser(jwtDecode(token)));
        dispatch({
          type: SIGNIN_USER_SUCCESS,
          payload: false,
          isSigninIn: true,
          msg: res.data.msg,
        });
        toast.success("User Logged In!");
        window.location.href = "/#/dashboard";
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: SIGNIN_USER_ERROR,
          loading: false,
          payload: false,
          msg: error.msg,
        });
      });
  };
};

export const signOut = () => {
  return function (dispatch) {
    dispatch({
      type: SIGNIN_USER_REQUEST,
      payload: true,
    });
    localStorage.removeItem("token");
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
    dispatch({
      type: SIGNOUT_USER,
      payload: false,
    });
    toast.success("Logout!");

    dispatch({
      type: SIGNIN_USER_ERROR,
      loading: false,
      payload: "",
    });
  };
};
// SET CURRENT USER ACTION
export const setCurrentUser = (result) => {
  return {
    type: SET_CURRENT_USER,
    payload: false,
    result: result,
  };
};

// Register User
export const register = (
  first_name,
  mid_name,
  last_name,
  email,
  mobile,
  gender,
  nationality,
  age,
  maritial_status,
  reffer_by,
  password,
  confirm_password
) => {
  return function (dispatch) {
    dispatch({
      type: USER_REGISTER_REQUEST,
      payload: true,
    });

    let OPTION = {
     // url: `http://103.145.51.109:3004/api/v1/user/register`,
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user/register`,

      method: "POST",
      data: {
        first_name,
        mid_name,
        last_name,
        email,
        mobile,
        gender,
        nationality,
        age,
        maritial_status,
        reffer_by,
        password,
        confirm_password,
      },

      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log(res, "newdata");
        dispatch(registerPre(res.data));
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        window.location.href = "/#/signin";
      })

      .catch((error) => {
        dispatch({
          type: USER_REGISTER_FAIL,
          payload: false,
          error: error,
          isSignUp: error.success,
          msg: "Failed to create user registration",
        });
        toast.error(error.msg);
      });
  };
};

export const registerPre = (data) => {
  toast.success(data.msg);
  return {
    type: USER_REGISTER_SUCCESS,
    isSignUp: data.success,
    result: data,
    payload: false,
    msg: data.msg,
  };
};




// import setAuthorizationToken from "../../utils/setAutorization"; 
// import "react-toastify/dist/ReactToastify.css";
// import { toast } from "react-toastify";

// // import jwtDecode from "jwt-decode";
// import {
//   SIGNIN_USER_SUCCESS,
//   SIGNIN_USER_REQUEST,
//   SIGNIN_USER_ERROR,
//   SET_CURRENT_USER,
//   SIGNOUT_USER,
//   SEND_USER_OTP_VERIFY,
//   USER_REGISTER_SUCCESS,
//   USER_REGISTER_FAIL,
//   USER_REGISTER_REQUEST,
// } from "../constant/AuthConstant";
// const axios = require("axios");


// //otp verify
// export const senduserotpverify = (number) => {
//   return function (dispatch) {
//     dispatch({
//       type: SEND_USER_OTP_VERIFY,
//       payload: true,
//     });
//     let OPTIONS = {
//       url: `${process.env.REACT_APP_API_PATH}/api/v1/login`,
//       method: "POST",
//       data: {
//         otp:number,
//       },
//       headers: {
//         "content-type": "application/jsion",
//       },
//     };

//     axios(OPTIONS)    
//     .then((res) => {
//       console.log("responsess", res.data);

//       // const token = res.data.token;
//       // localStorage.setItem("token", token);
//       // setAuthorizationToken(token);
//       dispatch(setCurrentUser(res.data));
//       dispatch({
//         type: SEND_USER_OTP_VERIFY,
//         payload: false,
//         isSigninIn: true,
//         msg: res.data.msg,
//       });
//       toast.success("User Logged In!");
//       window.location.href = "/#/otp";
//     })
//     .catch((error) => {
//       console.log("error", error);
//       dispatch({
//         type:SEND_USER_OTP_VERIFY,
//         loading: false,
//         payload: false,
//         msg: error.msg,
//       });
//     });
//   }
// }


// // USER AUTH ACTION
// export const signinUser = (number) => {
//   // const navigate = useNavigate();  
//   return function (dispatch) {
//     dispatch({
//       type: SIGNIN_USER_REQUEST, 
//       payload: true,
//     });

//     let OPTIONS = {  
//       // url: `${process.env.REACT_APP_API_PATH}/api/v1//v1/send-otp`,
//       url: `${process.env.REACT_APP_API_PATH}/api/v1/send-otp`,
//       method: "POST",
//       data: {
//         mobile: number,
  
//       },
//       headers: {
//         "content-type": "application/json",
//       }, 
//     };

//     axios(OPTIONS)    
//       .then((res) => {
//         console.log("res", res.data);

//         // const token = res.data.token;
//         // localStorage.setItem("token", token);
//         // setAuthorizationToken(token);
//         dispatch(setCurrentUser(res.data));
//         dispatch({
//           type: SIGNIN_USER_SUCCESS,
//           payload: false,
//           isSigninIn: true,
//           msg: res.data.msg,
//         });
//         toast.success("User Logged In!");
//         window.location.href = "/#/otp";
//       })
//       .catch((error) => {
//         console.log("error", error);
//         dispatch({
//           type: SIGNIN_USER_ERROR,
//           loading: false,
//           payload: false,
//           msg: error.msg,
//         });
//       });
//   };
// };

// export const signOut = () => {
//   return function (dispatch) {
//     dispatch({
//       type: SIGNIN_USER_REQUEST,
//       payload: true,
//     });
//     localStorage.removeItem("token");
//     setAuthorizationToken(false);
//     dispatch(setCurrentUser({}));
//     dispatch({
//       type: SIGNOUT_USER,
//       payload: false,
//     });
//     toast.success("Logout!");

//     dispatch({
//       type: SIGNIN_USER_ERROR,
//       loading: false,
//       payload: "",
//     });
//   };
// };
// // SET CURRENT USER ACTION
// export const setCurrentUser = (result) => {
//   return {
//     type: SET_CURRENT_USER,
//     payload: false,
//     result: result,
//   };
// };
// // export const setCurrentOTP = (result) => {
// //   return {
// //     type: SET_CURRENT_OTP,
// //     payload: false,
// //     result: result,
// //   };
// // };

// // Register User
// export const register = (
//   first_name,
//   last_name,
//   mid_name,
//   email,
//   password,
//   mobile,
//   gender,
//   nationality,
//   age,
//   maritial_status,
//   reffer_by
// ) => {
//   return function (dispatch) {
//     dispatch({
//       type: USER_REGISTER_REQUEST,
//       payload: true,
//     });

//     let OPTION = {
//       url: "http://103.145.51.109:3004/api/v1/register",

//       method: "POST",
//       data: {
//         first_name,
//         last_name,
//         mid_name,
//         email,
//         password,
//         mobile,
//         gender,
//         nationality,
//         age,
//         maritial_status,
//         reffer_by,
//       },

//       headers: {
//         "content-type": "application/json",
//       },
//     };

//     axios(OPTION)
//       .then((res) => {
//         dispatch(registerPre(res.data));
//         localStorage.setItem("userInfo", JSON.stringify(res.data));
//         window.location.href = "/#/signin";
//       })

//       .catch((error) => {
//         dispatch({
//           type: USER_REGISTER_FAIL,
//           payload: false,
//           error: error,
//           isSignUp: error.success,
//           msg: "Failed to create user registration",
//         });
//         toast.error(error.msg);
//       });
//   };
// };

// // export const userOTP = (number) => {
// //   return function (dispatch) {
// //     dispatch({
// //       type: SEND_USER_OTP,
// //       payload: true,
// //     });

// //     let OPTION = {
// //       url: `http://103.145.51.109:3004/api/v1/send-otp`,

// //       method: "POST",
// //       data: {
// //         mobile: number,
// //       },

// //       headers: {
// //         "content-type": "application/json",
// //       },
// //     };

// //     axios(OPTION)
// //       .then((res) => {
// //         dispatch(setCurrentOTP(res.data));
      
// //         window.location.href = "/#/signin";
// //       })

// //       .catch((error) => {
// //         // dispatch({
// //         //   type: USER_REGISTER_FAIL,
// //         //   payload: false,
// //         //   error: error,
// //         //   isSignUp: error.success,
// //         //   msg: "Failed to create user registration",
// //         // });
// //         toast.error(error.msg);
// //       });
// //   };
// // };


// export const registerPre = (data) => {
//   toast.success(data.msg);
//   return {
//     type: USER_REGISTER_SUCCESS,
//     isSignUp: data.success,
//     result: data,
//     payload: false,
//     msg: data.msg,
//   };
// };

